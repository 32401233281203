import React from "react";
import style from './index.module.less'
import { ShiXinBaiBtn_updown } from '@/components/index'
import { useIntl } from "react-intl";
import {developerBg} from '@/images/support/index'
const Developer = () => {
    const intl = useIntl()
    return (
        <div style={{ width: "100%"}} className={style.dev}>
            <div className={style.box} style={{backgroundImage:`url(${developerBg})`}}>
                <div className={style.content}>
                    <div className={style.title}>
                        {intl.formatMessage({ id: "developer_title", defaultMessage: "For Developers" })}
                    </div>
                    <div className={style.desc}>{intl.formatMessage({ id: "developer_text", defaultMessage: "World-class SDK to help you leverage the latest computer vision technologies to create innovative content" })}</div>
                    <ShiXinBaiBtn_updown onClick={() => { window.open(process.env.url_developer) }} styles={{margin:'0 auto'}}>
                        <div className={style.btn}>{intl.formatMessage({ id: "developer_btn", defaultMessage: "Go To Developer" })}</div>
                    </ShiXinBaiBtn_updown>
                </div>
            </div>
        </div>

    )
}

export default Developer