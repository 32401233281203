import React, { useEffect, useState } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { LightPro } from '@/images/support/index'

import { DetailHeader, DetailStart, Developer, DetailFaq } from '@/components/Support/index'
import style from "./index.module.less"
import { CompatibilityMobileList } from '@/components/index'
import getCountry from '@/utils/getCountry.js'
import { getAdaptByCountry } from '@/services/request'
import { useIntl } from "react-intl"
import { getCompatibilityCode_light } from '@/utils/regions.js'

const Index = () => {
  const compCode = getCompatibilityCode_light()

  const intl = useIntl()
  const [comList, setComList] = useState([])
  const country = getCountry()
  useEffect(() => {


    getAdaptByCountry({ countryCode: compCode }).then(
      (res) => {
        let {code,data} = res.data
        if(code==200){
        let allList = data
        let lightList = allList.filter(item => item.devices?.includes('XREAL Light') || item.devices?.includes('XREAL Light(Exclusive Version)'))
        setComList(lightList)
        }
      }
    )

  }, [])
  return (
    <Layout menuBlack={true}>
      <SEO page="Support" />
      <DetailHeader pro='light' />
      <DetailStart updateLink='/support/update/#light' manualsLink='/support/Nreal_Light_After_Sales_&_Warranty_US_pdf' type='light' />


      {comList.length !== 0 && <> 
      <div className={style.connect}>
        {intl.formatMessage({ id: "support_how_connect", defaultMessage: "Compatibility" })}
      </div><div style={{ padding: '0 30px' }}>

          <CompatibilityMobileList data={comList} />
        </div>
      </>}

      <Developer />
      <DetailFaq type='light' />

    </Layout>
  )
}

export default Index